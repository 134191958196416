import * as angular from 'angular';
import jQuery from "jquery";
import ClickController from "components/blocworx/controllers/click.controller";
import CheckController from "components/blocworx/controllers/check.controller";


"use strict";

angular.module('ModuleModule')
    .directive('checkChildDirective', CheckChildDirective);

CheckChildDirective.$inject = [];

function CheckChildDirective() {
    let directive = this;

    return ({
        controller: CheckController,
        restrict: "A",
        scope: {
        },
        link: function (scope, element, attributes) {

            let checkbox = jQuery(element).find('input[type=checkbox]');
            jQuery(checkbox).data('canClick', true);

            // If someone clicks to the td, it will be clicking to the
            // a element inside of this directive
            element.bind('click', function (event) {

                event.preventDefault();

                let canClick = jQuery(checkbox).data('canClick');

                console.log(canClick)

                if(checkbox.length > 0 && event.isTrusted && canClick){

                    jQuery(checkbox).data('canClick', false);
                    jQuery(checkbox)[0].click();

                    setTimeout(() => {
                        jQuery(checkbox).data('canClick', true)
                        console.log(jQuery(checkbox).data('canClick'))
                    }, 500)

                }
            });
        }
    });
}
import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import AdminController from "./controllers/admin.controller";

"use strict";

class AdminComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/admin';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        'bootstrap': '<',
    }

    // This will set what will be the controller of this component
    public controller : any = AdminController;

    // This will set an alias
    public controllerAs: string = 'ac';
}

angular.module('BlocworxModule').component('admin', <StandardComponent> new AdminComponent);
import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('ToggleController', ToggleController);

ToggleController.$inject = ['$scope', 'ScopeAndLocal', 'AppSettings'];

// Controller Constructor
function ToggleController($scope, ScopeAndLocal, AppSettings) {

    // Creating the view model reference
    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = function () {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'bootstrap');
            ScopeAndLocal.startVariable(vm, $scope, 'nav');
            vm.appSettings = AppSettings;

        } catch (e) {
            console.log(e);
        }
    }
}

export default ToggleController;
import * as angular from 'angular';
import $ from 'jquery';

'use strict';

angular.module('BlocworxModule')
       .provider('Blocworx', Blocworx);

Blocworx.$inject = [];

/**
 *  Object that will create a Icon Factory, all methods of factor
 *
 * @constructor
 */
function Blocworx() {

    let Blocworx = this;

    Blocworx.$get = function() {

        return Blocworx;

    };

    /**
     * This method will be checking if the object is empty.
     * @param obj
     */

    Blocworx.isEmpty = (value) => {
        return (
            // null or undefined
            (value == null) ||

            // has length and it's zero
            (value.hasOwnProperty('length') && value.length === 0) ||

            // is an Object and has no keys
            (value.constructor === Object && Object.keys(value).length === 0)
        )
    }

    /**
     * This will be responsible to check the data that comes from an
     * $http, by using out data.data.data this can be avoiding to check this
     * and getting if exist, otherwise will return an empty object.
     *
     * @param response
     */
    Blocworx.getData = (response) => {
        let data = {};
        if(response.data != undefined && response.data.data.length !=undefined){
            data = response.data.data;
        }
        return data;
    }


    /**
     * This is a good way to append data to an array using ES6 to
     * the end of the array, this will be localized as next available
     * position at the array end.
     * @param request
     * @param dataToAppend
     */
    Blocworx.appendEnd = (request, dataToAppend) => {
        return [...request, dataToAppend]
    }

    /**
     * This is a good way to append data to the start of the array
     * this will get your data, and append the array after.
     *
     * @param request
     * @param dataToAppend
     */
    Blocworx.appendStart = (request, dataToAppend) => {
        return [...dataToAppend, ...request]
    }

    return Blocworx;
}

import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('CopyrightController', CopyrightController);

CopyrightController.$inject = ['$scope', 'ScopeAndLocal', 'Configuration'];

// Controller Constructor
function CopyrightController($scope, ScopeAndLocal, Configuration) {

    // Creating the view model reference
    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = async () => {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'bootstrap');
            ScopeAndLocal.startVariable(vm, $scope, 'nav');
            vm.random = Configuration.getRandomValue();
            vm.subDomain = vm.bootstrap.subDomain
            vm.appSettings = vm.bootstrap.appSettings;

        } catch (e) {
            console.log(e);
        }
    }


}

export default CopyrightController;
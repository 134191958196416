"use strict";

import * as angular from "angular";

angular.module('BlocworxModule').config(homeRoutes);

homeRoutes.$inject = ['ConfigurationProvider', '$stateProvider'];

function homeRoutes(ConfigurationProvider, $stateProvider)
{
    $stateProvider.state('app', {
        views: {
            'container': {
                templateUrl: 'components/blocworx/template/bootstrap.html?nd=' + Date.now(),
                controller: 'BootstrapController as bootstrap'
            }
        },
        params: {
            sidebarHighlighter: 'home'
        }
    }).state('home', {
        parent: 'app',
        url: '/',
        views: {
            'parent': {
                templateUrl: 'components/blocworx/template/home.html?nd=' + Date.now(),
                controller: 'MainController as main'
            }
        },
        params: {
            sidebarHighlighter: 'home'
        }
    }).state('404', {
        parent: 'app',
        url: '/404',
        views: {
            'parent': {
                templateUrl: 'components/blocworx/template/404.html?nd=' + Date.now(),
                controller: 'BootstrapController as bootstrap'
            }
        },
        params: {
            sidebarHighlighter: 'home'
        }
    });

}
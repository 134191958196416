import * as angular from 'angular';

'use strict';

angular.module('BlocworxModule')
       .factory('URLService', URLService);

URLService.$inject = ['$window', 'Configuration'];


function URLService($window, Configuration) {

    let service = this;
    let apiUrl = Configuration.getApiUrl();
    

    /**
     * Constructor method.
     */
    service.$onInit = () => {
        try
        {

        } catch (exception) {
            console.log(exception);
        }
    }

    service.getCurrentPath = () => {
        return window.location.pathname
    }

    // this will make sure that will be the constructor class like a controller one
    service.$onInit();

    return service;
}

import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('ForbiddenController', ForbiddenController);

ForbiddenController.$inject = ['$scope', 'ScopeAndLocal'];

// Controller Constructor
function ForbiddenController($scope, ScopeAndLocal) {

    // Creating the view model reference
    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'validation');

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This is the main validation that are spread though the system, as it is
     * very basic of a lot of pages that is the reason of the name "main" of it.
     */
    vm.mainValidation = () => {
        return vm.main.isCustomer == 1 || vm.main.roleFailed == false;
    }

}

export default ForbiddenController;
import * as angular from 'angular';
import AdvancedReportsController from './controllers/advanced-reports.controller';
import StandardComponent from 'components/standards/components/StandardComponent';

"use strict";

class AdvancedReportsComponent extends StandardComponent {

    // This will enable the debug mode for this component
    public debug = false;
    public moduleFolder = 'components/advanced-reports';
    
    public bindings = {
        main: '<',
        scanStation: '<',
        data: '<',
        action: '<',
        field: '<',
    }

    // This will set what will be the controller of this component
    public controller: any = AdvancedReportsController;

    // This will set an alias for this component
    public controllerAs: string = 'ar';
}

angular.module('AdvancedReportsModule').component('advancedReports', <StandardComponent> new AdvancedReportsComponent);
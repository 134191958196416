import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular.module('BlocworxModule')
    .controller('AdminController', AdminController);

// Injection of each class
AdminController.$inject = [ '$scope', 'ScopeAndLocal', 'MessageService',
    'ThemeService', 'AppSettings', 'FileService', 'Configuration' ];

// Controller Constructor
function AdminController($scope, ScopeAndLocal, MessageService,
    ThemeService, AppSettings, FileService, Configuration) {

    let vm = this;

    vm.$onInit = async function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'bootstrap');
            vm.theme = ThemeService;
            vm.appSettings = vm.bootstrap.appSettings;
            vm.logoMaxSize = ThemeService.getLogoMaxSizeMessage();
            vm.backgroundHeaderMaxSize = ThemeService.getHeaderBackgroundMaxSizeMessage();

        } catch ( e ) {
            console.log(e);
        }
    }

    /**
     * This will be updating the main logo
     * of the website for each cartolytics_customer_id.
     *
     * @param logo
     */
    vm.updateMainLogo = async (logo) => {
        try {
            await ThemeService.validateMainLogo(logo);
            await ThemeService.updateMainLogo(logo);
            await ThemeService.getMainLogo();

            vm.logoSrc = undefined;
            $('input[name=logo]').val(null);

            MessageService.addSuccessMessage('Image updated');

        } catch ( e ) {
            console.log(e);
            MessageService.addErrorMessage(e.message);
        } finally {
            $scope.$apply();
        }
    }

    /**
     * This will be deleting any main_logo on the
     * app_setting, that contain the current cartolytics_customer_id.
     */
    vm.deleteMainLogo = async () => {
        try
        {
            await ThemeService.deleteMainLogo();
            await ThemeService.getMainLogo();
            MessageService.addSuccessMessage('Image deleted, you must get the main blocworx image');

        }  catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }
    }

    /**
     * This will be deleting any sidebar_logo on the
     * app_setting, that contain the current cartolytics_customer_id.
     */
    vm.deleteCustomLogo = async (key, path) => {
        try {

            let subDomain = Configuration.getSubDomain();
            let extension = FileService.getFileExtension(path);
            let pathToRemove = `/app/files/customer-logos/${subDomain}${extension}`

            // step 1: remove from the database level
            await AppSettings.removeAppSettings(key);

            // step 2: remove the file itself on the path
            await FileService.removeCustomLogo(pathToRemove);

            // Step 2: remove the customerLogo path from the bootstrap
            vm.bootstrap.customerLogo = '';
            vm.bootstrap.appSettings.sidebar.logo.enabled = false

            MessageService.addSuccessMessage(`Removed => ${key}`);

        } catch ( e ) {
            console.log(e);
        } finally {
            $scope.$apply();
        }
    }

    /**
     * This will be responsible to upgrade the background of
     * the header.
     *
     * @param logo
     */
    vm.updateHeaderBackground = async (headerBackground) => {
        try {
            await ThemeService.validateHeaderBackground(headerBackground);
            await ThemeService.updateHeaderBackground(headerBackground);
            await ThemeService.getHeaderBackground();

            vm.headerBackground = undefined;
            $('input[name=headerBackground]').val(null);

            MessageService.addSuccessMessage('Header background updated');

        } catch ( e ) {
            console.log(e);
            MessageService.addErrorMessage(e.message);
        } finally {
            $scope.$apply();
        }
    }

    /**
     * This will be deleting any header_background on the
     * app_setting, that contain the current cartolytics_customer_id.
     */
    vm.deleteHeaderBackground = async () => {
        try {
            await ThemeService.deleteHeaderBackground();
            await ThemeService.getHeaderBackground();
            MessageService.addSuccessMessage('Image deleted, you must get the main blocworx image');

        } catch ( e ) {
            console.log(e);
        } finally {
            $scope.$apply();
        }
    }

    /**
     * This will call the service to enable/disable a property on app_settings.
     *
     * @param key
     * @param settingValue
     * @param enabled
     */
    vm.updateEnabledDisabledAppSettings = async (key, settingValue, enabled) => {
        try
        {
            if(key == "sidebar_logo"){
                if (typeof settingValue === 'string') {
                    settingValue = settingValue.replace(/\?.*$/, '');
                }

                // this is to make sure to show the sidebar logo when you enable something that we cant find on database
                // so it will be back to main image that shows that you can change things on the menu sidebar
                if(settingValue == ''){
                    settingValue = 'enable-disable-option';
                }
            }

            await vm.appSettings.updateEnabledDisabledAppSettings(key, settingValue, enabled)
            $scope.$apply();

        } catch ( e ) {
            console.log(e);
        }
    }


    /**
     * This method will be checking if we can show the button delete on /edit-theme
     * @param logo
     */
    vm.deleteLogoConditional = (logo : string) => {

        let shouldShow = true;

        // check if we have an empty value
        if(logo == '' || logo == undefined){
            return false;
        }

        // check if we have the default logo image
        if(logo.match(/default-logo-instruction/)){
            return false;
        }

        return shouldShow;

    }
}


export default AdminController;
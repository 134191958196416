import * as angular from 'angular';

"use strict";

angular.module('ButtonsModule')
       .controller('AddNewController', AddNewController);

// Injection of each class
AddNewController.$inject = ['$scope', 'ScopeAndLocal'];

// Controller Constructor
function AddNewController($scope, ScopeAndLocal) {

    let vm = this;

    vm.$onInit = async function () {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will validate if should show or hide the button
     */
    vm.click = () => {
        vm.scanStation.scanStationObj.showForm = true;
    }

    /**
     * This will validate if should show or hide the button
     */
    vm.validate = () => {

        let valid = false;

        if (vm.scanStation.scanStationObj.stationDetails.hide_add_new_button == 0 || ! vm.scanStation.scanStationObj.stationDetails.hide_add_new_button){
           if( ! vm.scanStation.isShowMoreViewing()){
               valid = true;
           }
        }

        return valid
    }

    /**
     * This will validate if we show or hide the button
     */
    vm.show = () => {

        let show = false;

        if(vm.scanStation.scanStationObj.showForm != true){
            show = true;
        }

        return show;

    }

    /**
     * This will be showing the rule for the special button with some extra text in:
     * Station Details addNewButtonText field.
     */
    vm.containButtonText = () => {

        let valid = false;

        if(vm.scanStation.scanStationObj.stationDetails.addNewButtonText != null && vm.scanStation.scanStationObj.stationDetails.addNewButtonText != ''){
            valid = true;
        }

        return valid
    }

    /**
     * This is the basic case of only showing the add New button, as we can't find any reference on the
     * addNewButtonText
     */
    vm.doesNotContainButtonText = () => {

        let valid = false;

        if(vm.scanStation.scanStationObj.stationDetails.addNewButtonText == null || vm.scanStation.scanStationObj.stationDetails.addNewButtonText == ''){
            valid = true;
        }

        // TODO: check with Adrian later if we can use the opposite of the contain button text
        // return ! vm.containButtonText();

        return valid
    }

}

export default AddNewController;
import * as angular from 'angular';
import ForbiddenController from "./controllers/forbidden.controller";
import StandardComponent from "components/standards/components/StandardComponent";

"use strict";

class BlocworxForbidden extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/forbidden';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        main: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = ForbiddenController;

    // This will set an alias
    public controllerAs: string = 'forbidden';
}

angular.module('BlocworxModule').component('blocworxForbidden', <StandardComponent> new BlocworxForbidden);
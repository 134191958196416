import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .service('ThemeFactory', ThemeFactory);

ThemeFactory.$inject = ['Configuration', '$http'];

/**
 * Service constructor.
 * @constructor
 */
function ThemeFactory(Configuration, $http) {
    let factory = this;

}
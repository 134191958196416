import * as angular from 'angular';
import IsIframeController from "components/blocworx/controllers/isIframe.controller";
import * as jQuery from "jquery";

'use strict';

angular.module('BlocworxModule')
       .directive('isIframe', isIframe);

isIframe.jQueryinject = [];

/**
 *  Object that will create a Icon Factory, all methods of factor
 *
 * @constructor
 */
function isIframe() {

    let isIframe = this;
    return ({
        controller: IsIframeController,
        restrict: "A",
        link: function (scope, element, attributes) {
            jQuery(document).ready(function() {
                // this will be adding a class for the main blocworx element
                if(jQuery('#blocworx')){
                    let blocworx = jQuery('#blocworx').parent().parent();
                    // blocworx.attr('id', 'blocworx-main');
                    blocworx.addClass('blocworx-main');
                }

                // this will be adding a class for the iframe element
                if(jQuery('#iframe')){
                    let blocworx = jQuery('#iframe').parent().parent();
                    blocworx.addClass('blocworx-iframe');
                }
            });
        }
    });
}

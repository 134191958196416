import * as angular from 'angular';
import LogoDefault from "@images/blocworx-logo-2022.svg";
import HeaderBackgroundDefault from "@/images/header-bg.png";

"use strict";

angular.module('BlocworxModule')
       .service('ThemeService', ThemeService);

ThemeService.$inject = ['Configuration', '$http', 'AdminService', 'FileService', 'Data'];

/**
 * Service constructor.
 * @constructor
 */
function ThemeService(Configuration, $http, AdminService, FileService, Data) {

    let service = this;

    // logo configurations
    service.logoMaxWidth = 210;
    service.logoMaxHeight = 86;

    // header background configurations
    service.headerBackgroundMaxWidth = 1900;
    service.headerBackgroundMaxHeight = 90;

    let apiUrl = Configuration.getApiUrl();

    /**
     * Method that will return the max size limit.
     */
    service.getLogoMaxSizeMessage = () => {
        return `${service.logoMaxWidth}x${service.logoMaxHeight}`;
    }

    /**
     * Method that will return the max size limit.
     */
    service.getHeaderBackgroundMaxSizeMessage = () => {
        return `${service.headerBackgroundMaxWidth}x${service.headerBackgroundMaxHeight}`;
    }

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getMainLogo = async () => {

        let fd = new FormData();
        let cartolyticsCustomerID = await Data.getCartolyticsCustomerID();

        let apiBase = apiUrl + 'get-main-logo';
        fd.append('cartolyticsCustomerID', cartolyticsCustomerID);

        let srcFromDatabase = await $http.post(apiBase, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });

        service.logoSrc = srcFromDatabase.data !== undefined && srcFromDatabase.data.logo != undefined ? srcFromDatabase.data.logo : LogoDefault;
        service.customMainLogo = srcFromDatabase.data !== undefined && srcFromDatabase.data.custom != undefined ? srcFromDatabase.data.custom : false;

        // this is a measure to remove the cache in this image
        service.logoSrc = `${service.logoSrc}?v=${Configuration.getRandomValue()}`;

        return service.logoSrc;
    }

    /**
     * Returns the initial Parameters for this field, to be distinguished from other parameters the field may have in the future after creating it
     */
    service.getHeaderBackground = async () => {

        let fd = new FormData();
        let cartolyticsCustomerID = await Data.getCartolyticsCustomerID();

        let apiBase = apiUrl + 'get-header-background';
        fd.append('cartolyticsCustomerID', cartolyticsCustomerID);

        let srcFromDatabase = await $http.post(apiBase, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });

        service.headerBackground = srcFromDatabase.data !== undefined && srcFromDatabase.data.headerBackground != undefined ? srcFromDatabase.data.headerBackground : HeaderBackgroundDefault;
        service.customHeader = srcFromDatabase.data !== undefined && srcFromDatabase.data.custom != undefined ? srcFromDatabase.data.custom : false;

        // this is a measure to remove the cache in this image
        service.headerBackground = `${service.headerBackground}?v=${Configuration.getRandomValue()}`;

        return service.headerBackground;
    }

    /**
     * This will be responsible to update the logo of
     * the website.
     *
     * @param logo
     */
    service.updateMainLogo = async (logo) => {

        let fd = new FormData();
        let cartolyticsCustomerID = await Data.getCartolyticsCustomerID();

        let apiBase = apiUrl + 'update-main-logo';
        fd.append('cartolyticsCustomerID', cartolyticsCustomerID);
        fd.append('logo', logo);

        let data = await $http.post(apiBase, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });
        return data;
    }

    /**
     * This will be responsible to update the logo of
     * the website.
     *
     * @param logo
     */
    service.updateHeaderBackground = async (logo) => {

        let fd = new FormData();
        let cartolyticsCustomerID = await Data.getCartolyticsCustomerID();

        let apiBase = apiUrl + 'update-header-background';
        fd.append('cartolyticsCustomerID', cartolyticsCustomerID);
        fd.append('headerBackground', logo);

        let data = await $http.post(apiBase, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });
        return data;
    }

    /**
     * This will be responsible to delete current set logo
     * and mostly will be back t the main blocworx one.
     *
     * @param logo
     */
    service.deleteMainLogo = async (logo) => {

        let fd = new FormData();
        let cartolyticsCustomerID = await Data.getCartolyticsCustomerID();

        let apiBase = apiUrl + 'delete-main-logo';
        fd.append('cartolyticsCustomerID', cartolyticsCustomerID);

        let data = await $http.post(apiBase, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });
        return data;
    }

    /**
     * This will validate the main logo.
     * @param logo
     */
    service.validateMainLogo = async (logo) => {

        if(logo == undefined){
            throw new Error("Logo: You must select a file to upload first.");
        }

        // let fileDimensions = await FileService.getHeightAndWidth(logo);
        //
        // if(fileDimensions.height > service.logoMaxHeight){
        //     throw new Error("Logo: Height too long");
        // }
        //
        // if(fileDimensions.width > service.logoMaxWidth){
        //     throw new Error("Logo: Width too long");
        // }
    }

    /**
     * This will validate the main logo.
     * @param headerBackground
     */
    service.validateHeaderBackground = async (headerBackground) => {

        if(headerBackground == undefined){
            throw new Error("Header Background: You must select a file to upload first.");
        }

        let fileDimensions = await FileService.getHeightAndWidth(headerBackground);

        if(fileDimensions.height > service.headerBackgroundMaxHeight){
            throw new Error("Header Background: Height too long");
        }

        if(fileDimensions.width > service.headerBackgroundMaxWidth){
            throw new Error("Header Background: Width too long");
        }
    }

    /**
     * This will be responsible to delete current header background
     * and mostly will be back t the main blocworx one.
     *
     * @param logo
     */
    service.deleteHeaderBackground = async (logo) => {

        let fd = new FormData();
        let cartolyticsCustomerID = await Data.getCartolyticsCustomerID();

        let apiBase = apiUrl + 'delete-header-background';
        fd.append('cartolyticsCustomerID', cartolyticsCustomerID);

        let data = await $http.post(apiBase, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        });
        return data;
    }

}
import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule', [
    'ui.router', 'ngRoute', 'ngAnimate', 'ngSanitize', 'ui.bootstrap', 'ui.bootstrap.modal', require('ng-orwell'), 'ngMaterial'
    ]
);

export default 'BlocworxModule';

import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import FormBlocworxController from "components/blocworx/components/form/controllers/formBlocworx.controller";


"use strict";

class FormBlocworx extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/form';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        data: '<',
        main: '<',
        management: '<'
    }

    // This will set what will be the controller of this component
    public controller : any = FormBlocworxController;

    // This will set an alias
    public controllerAs: string = 'fb';
}

angular.module('BlocworxModule').component('formBlocworx', <StandardComponent> new FormBlocworx);


import * as angular from 'angular';

'use strict';

angular.module('BlocworxModule')
    .factory('NavbarSelectedService', NavbarSelectedService);

NavbarSelectedService.$inject = ['$http', 'Configuration'];

/**
 * Object that will create a Navbar service for
 * knowing more about the current page into the navbar
 * details.
 *
 * @param $http
 * @returns {NavbarSelectedService}
 * @constructor
 */
function NavbarSelectedService($http, Configuration) {

    let NavbarSelectedService = this;

    /**
     * Constructor method.
     */
    NavbarSelectedService.$onInit = () => {
        try
        {
            NavbarSelectedService.selected = false;

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will select a navbar highlight
     * @param selection
     */
    NavbarSelectedService.set = (selection) => {
        NavbarSelectedService.selected = selection;
    }

    NavbarSelectedService.get = (selection) => {
        return NavbarSelectedService.selected;
    }

    // this will make sure that will be the constructor class like a controller one
    NavbarSelectedService.$onInit();

    return NavbarSelectedService;
}

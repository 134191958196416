import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('VersionController', VersionController);

VersionController.$inject = ['$scope','ScopeAndLocal', 'Configuration'];

// Controller Constructor
function VersionController($scope, ScopeAndLocal, Configuration) {

    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'bootstrap');

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will be responsible to load a versionUpdateMessage if exist
     * otherwise goes to the default Disconnected, please refresh.
     */
    vm.getVersionMessage = () => {

        let message = 'Disconnected, Please refresh.';

        if(vm.bootstrap != undefined && vm.bootstrap.versionUpdateMessage != null && vm.bootstrap.versionUpdateMessage != ''){
            message = vm.bootstrap.versionUpdateMessage;
        }

        return message;
    }

}

export default VersionController;
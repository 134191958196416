import * as angular from 'angular';
import moment from 'moment';
import jQuery from "jquery";

"use strict";

angular.module('AdvancedReportsModule')
    .controller('AdvancedReportsController', AdvancedReportsController);

// Injections
AdvancedReportsController.$inject = ['AdvancedReportsService', '$scope', 'ScopeAndLocal', 'ScanStationFactory'];

// Controller Constructor
function AdvancedReportsController(AdvancedReportsService, $scope, ScopeAndLocal, ScanStationFactory) {
    let vm = this;

    vm.$onInit = function () {
        try {
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'action');
            ScopeAndLocal.startVariable(vm, $scope, 'field');

        } catch (error) {
            console.log(error);
        }
    }

    vm.getDataReport = async function (stationID: string, stationName: string, fieldsToReport: string, requestData: string, responseType: string, infoPage: string, dataFilteringData: string) {

        try {
            vm.loadingReport = []
            vm.loadingReport[stationID + 'data-report'] = true;
            vm.scanStationFieldTypes = []
            
            const reportData = await AdvancedReportsService.getDataReport(stationID, fieldsToReport, requestData, responseType, infoPage, dataFilteringData);

            angular.forEach(vm.stationToShow, function (value, index) {
                vm.scanStationFieldTypes[value.field_slug] = value.field_type;
            })

            vm.loadingReport = false
            if (responseType == 'blob') {
                vm.openExcelFile(stationName, reportData.data);
            } else if (responseType == '') {
                if (typeof reportData != 'undefined') {
                    jQuery('html', 'body').animate({
                        scrollTop: "0px"
                    }, 800);
                    vm.dataToReportOnScreen = reportData.data.data;
                }
            }
            $scope.$apply();
        } catch (error) {
            console.log(error);
        }
    }

    vm.openExcelFile = function (stationName: string, data: any) {

        try {
            let date = moment().format("-MMM-Do-YYYY");
            const url = window.URL || window.webkitURL;
            vm.fileUrl = url.createObjectURL(data);
            let a = document.createElement("a");
            a.href = vm.fileUrl;
            a.download = stationName + date + '.xlsx';
            document.body.appendChild(a);
            a.click();
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    vm.selectAll = (fieldName: string) => {
        jQuery(`input[name="${fieldName}"]`).map((index, data) => {
            if (!jQuery(data).is(":checked")) {
                jQuery(data).trigger("click");
            }
        })
    }

    vm.unselectAll = (fieldName: string) => {
        jQuery(`input[name="${fieldName}"]`).map((index, data) => {
            if (jQuery(data).is(":checked")) {
                jQuery(data).trigger("click");
            }
        })
    }


    vm.removeFieldsNotInThisStation = function (fields) {
        try {
            let fieldsArray = [];
            angular.forEach(fields, function (value) {
                fieldsArray.push(value.field_slug);
            });

            angular.forEach(vm.summaryFieldsToReport, function (value, index) {
                if (!fieldsArray.includes(index)) {
                    delete (vm.summaryFieldsToReport[index]);
                }
            });

        } catch (e) {
            console.log(e);
        }
    }

    vm.getSummaryCountReport = async function (stationID, stationName, fieldsToReport, requestData) {

        try {
            vm.loadingReport = []
            vm.loadingReport[stationID + 'summary-count'] = true;
            const resultData = await AdvancedReportsService.getSummaryCountReport(stationID, requestData, fieldsToReport);
            vm.loadingReport = false
            vm.openExcelFile(stationName + '-summary', resultData.data)
            $scope.$apply();

        } catch (error) {
            console.log(error);
            alert('An Error has occurred and this report has failed to generate. Please contact the administrator.');
        }
    }

    vm.addAutomatedReport = async function () {
        try {
            await AdvancedReportsService.addAutomatedReport(vm.newAutomatedReport);
            await vm.data.getAutomatedReports()
            $scope.$apply();

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * These are the cases when we are ready to show the data reports in the bloc
     */
    vm.showAdvancedReportCase = function() {
        return (
            (vm.main.userRoles.includes('raw-data-and-count-reports') || vm.main.userRoles.includes('admin') || vm.main.userRoles.includes('admin-front-end')) ||
            (vm.scanStation.scanStationObj.stationDetails.hideAdvancedReportOption != 1));
    }

    /**
     * loading the scan station fields when we click on the
     * checkbox by scan station ID.
     *
     * @param id ID of the scan station
     */
    vm.loadScanStationFields = async (station, status = false) => {

        try {
            if(station.fields == null){
                station.fields = await ScanStationFactory.loadScanStationFields(station.id);
            } else {
                let count = await ScanStationFactory.getCountScanStationFields(station.id);
                if(count != station.fields.length){
                    station.fields = await ScanStationFactory.loadScanStationFields(station.id);
                }
            }

        } catch ( e ) {
            console.log(e)
        } finally {
            $scope.$apply();
        }
    }

    /**
     * This will run only if we have the checkbox unselected, so when is selected
     * must show data of the scan station fields.
     *
     * @param station
     * @param status
     */
    vm.loadScanStationIfCheckboxChecked = async (station, status) => {

        let checked = ScanStationFactory.stringToBoolean(status);
        if(checked == false){
            await vm.loadScanStationFields(station);
        }

    }

    /**
     * This method will be doing the edit action for the automated report,
     * for this to happen we need to load the scan station fields before the edit AutomatedReport array.
     * @param report
     * @param $index
     */
    vm.editAutomatedReport = async (report, $index) => {

        try {
            // adding the report as report to edit on the data
            vm.data.reportToEdit=report;
            vm.data.editAutomatedReport[$index] = true;

        } catch ( e ) {
            console.log(e)
        }

    }

}

export default AdvancedReportsController;
import * as angular from 'angular';

'use strict';

angular.module('BlocworxModule')
       .service('LookupService', LookupService);


LookupService.$inject = ['Data', '$http', 'Configuration'];

/**
 * This will be responsible to implement LookupService on the application.
 * @constructor
 * @deprecated
 */
export default function LookupService(Data, $http, Configuration) {

    const service = this;
    let apiUrl = Configuration.getApiUrl();

    /**
     * This will return the module categories for
     * a current Cartolytics Customer ID
     */
    service.getModuleCategory = async () => {
        try
        {
            let categories = []
            let apiBase = apiUrl + 'get-job-statuses';
            let config = {
                params: {}
            };

            let response = await $http.get(apiBase, config);
            if(response.data != undefined && response.data.data != undefined){
                categories = response.data.data;
            }

            return categories;

        } catch (exception){
            console.log(exception)
        }
    }


    return service;

}

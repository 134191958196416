import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import AddNewController from "components/buttons/components/addNew/controllers/addNew.controller";


"use strict";

class AddNewButton extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/buttons/components/addNew';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = AddNewController;

    // This will set an alias
    public controllerAs: string = 'addnew';
}

angular.module('FieldModule').component('addNewButton', <StandardComponent> new AddNewButton);
import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .service('FileService', FileService);

FileService.$inject = ['Configuration', '$http'];

/**
 * Service constructor.
 * @constructor
 */
function FileService(Configuration, $http) {

    let service = this;
    let apiUrl = Configuration.getApiUrl();

    /**
     * This will return the dimensions of a file from window.URL.createObjectURL.
     * @param dataURL
     */
    service.getHeightAndWidthFromDataUrl = dataURL => new Promise(resolve => {
        const img = new Image()
        img.onload = () => {
            resolve({
                height: img.height,
                width: img.width
            })
        }
        img.src = dataURL
    });

    /**
     * This will return the dimension of a file before sent to
     * a php level.
     * @param file
     */
    service.getHeightAndWidth = async (file) => {
        const fileAsDataURL = window.URL.createObjectURL(file);
        let data = await service.getHeightAndWidthFromDataUrl(fileAsDataURL)
        return data;
    }

    /**
     * This will remove the custom logo by deleting the path of it.
     *
     * @param path
     */
    service.removeCustomLogo = async (path) => {
        try
        {
            let apiBase = apiUrl + 'remove-custom-logo';

            let config = {
                params : {
                    path: path,
                }
            };

            await $http.delete(apiBase, config);

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will return the file extension on a ES6 level.
     * @param filename
     */
    service.getFileExtension = (filename) =>
    {
        let removedQuestionMark = filename.replace(/\?.*$/, '');
        let removedEverythingBeforeTheDot = removedQuestionMark.replace(/.*\./, '.');
        return removedEverythingBeforeTheDot
    }

}
import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import ScanStationController from "./controllers/scanstation.controller";

class DeleteScanStationDataComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/scanStation';
    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        main: '<',
        scanStation: '<',
        data: '<'
    }

    // This will set what will be the controller of this component
    public controller : any = ScanStationController;

    // This will set an alias
    public controllerAs: string = 'scan';
}

angular.module('BlocworxModule').component('deleteScanStationData', <StandardComponent> new DeleteScanStationDataComponent);

class UpdateScanStationAlert extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/scanStation';
    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        main: '<',
        scanStation: '<',
        data: '<',
        result: '<',
        userRoles : '<',
        lc : '<'
    }

    // This will set what will be the controller of this component
    public controller : any = ScanStationController;

    // This will set an alias
    public controllerAs: string = 'scan';
}

angular.module('BlocworxModule').component('updateScanStationAlert', <StandardComponent> new UpdateScanStationAlert);
import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .service('AdminService', AdminService);

AdminService.$inject = ['Configuration', '$http'];

/**
 * Service constructor.
 * @constructor
 */
function AdminService(Configuration, $http) {

    let service = this;
    let apiUrl = Configuration.getApiUrl();

}
import * as angular from 'angular';
import * as $ from "jquery";
import "../services/navbar.selected.service"

"use strict";

angular.module('BlocworxModule')
       .controller('NavbarController', NavbarController);

NavbarController.$inject = ['$scope','ScopeAndLocal','Data', 'AppSettings',
                            '$window', 'Auth', 'Configuration', 'NavbarSelectedService', 'Orwell'];

// Controller Constructor
function NavbarController($scope, ScopeAndLocal, Data, AppSettings,
                          $window, Auth, Configuration, NavbarSelectedService, Orwell) {

    // Creating the view model reference
    let vm = this;
    let version = Configuration.getVersion();
    let randomValue = Configuration.getRandomValue();
    let subDomain = Configuration.getSubDomain();

    vm.username = undefined;

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try {

            // console.log('Start: Navbar Controller');

            ScopeAndLocal.startVariable(vm, $scope, 'bootstrap');
            ScopeAndLocal.startVariable(vm, $scope, 'selected');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            Orwell.createObservable('selected');

            vm.version = version;
            vm.subDomain = subDomain;
            vm.userRoles = await Auth.getUserRoles();


        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This action will be loaded on the main navbar.html localized at
     * application/components/blocworx/components/navbar/template/navbar.html
     */
    vm.navbarStartActions = async () => {

        // This is to check if token exist before use something on Auth
        if (Auth.tokenExists()){
            let status = await Auth.isLoggedIn();
            if (status != undefined && status.name != undefined) {
                vm.username = status.name;
            }
        }

        await vm.loadCustomerLogo();
        vm.toggleSidebarWhenLinkIsSelected();
        vm.appSettings = vm.bootstrap.appSettings;
    }

    /**
     * This action will be setting the data on the navbarService as well as
     * it will create an observer and update the content that is stored on
     * the NavBarSelectedService (the current service that is enabling this pass values
     * between components)
     *
     * @param selected
     */
    vm.setSelected = (selected) => {
        NavbarSelectedService.set(selected);
        let observable = Orwell.getObservable('selected');
        observable.setContent(NavbarSelectedService.get());
    }

    /**
     * This will return the value on the observable content.
     */
    vm.getSelected = () => {
        return Orwell.getObservable('selected').content
    }

    /**
     * This will be responsible to check if exist a customer logo, and if exists
     * it will be loading the customer logo, otherwise it will be getting the
     * default-logo-instruction.png
     */
    /**
     * This will be responsible to check if exist a customer logo, and if exists
     * it will be loading the customer logo, otherwise it will be getting the
     * default-logo-instruction.png
     */
    vm.loadCustomerLogo = async () => {
        try {
            vm.bootstrap.customerLogo = await AppSettings.loadLogo(vm.bootstrap.subDomain + `.png`, vm.bootstrap.subDomain);
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This is a two step authentication that happens every time a page loads or a link is clicked to change view.
     * First step is to verify the user is logged in, second step is they have the correct permissions for the page
     * they are trying to access.
     * This process is also done on the server side with its own data. This means that even if the user successfully
     * got to a page when they shouldn't have they will still not be able to retrieve or add any data.
     */
    vm.reloadPage = function () {
        try {
            $window.location.reload();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will toggle the sidebar
     */
    vm.toggleSideBar = function () {
        try {
            $('.sidebar').toggleClass('open');
            $('blocworx-navbar').toggleClass('open');
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This function will be checking all the a elements of the menu
     * and it will be doing the toggle of the sidebar and the blocworx-sidebar
     * rules of opening and closing.
     */
    vm.toggleSidebarWhenLinkIsSelected = function () {
        try {

            $('blocworx-navbar').on("click", "a", () => {
                $('.sidebar').toggleClass('open');
                $('blocworx-navbar').toggleClass('open');
            });

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will be returning if we are logged in or not.
     * true: logged in
     * false: not logged in
     */
    vm.isLogged = () => {
        const logged = JSON.parse(sessionStorage.getItem('logged'));
        return logged === true ? true : false;
    }
}

export default NavbarController;
import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import MessageController from "components/blocworx/components/message/controllers/message.controller";

"use strict";

class BlocworxMessage extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/message';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        main: '<',
        data: '<',
        scanStation: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = MessageController;

    // This will set an alias
    public controllerAs: string = 'mc';
}

angular.module('BlocworxModule').component('blocworxMessage', <StandardComponent> new BlocworxMessage);
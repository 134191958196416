import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('MessageController', MessageController);

MessageController.$inject = ['$scope','ScopeAndLocal', 'MessageService'];

// Controller Constructor
function MessageController($scope, ScopeAndLocal, MessageService) {

    // Creating the view model reference
    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');

        } catch (e) {
            console.log(e);
        }
    }

    vm.closeSuccess = () => MessageService.closeSuccess();
    vm.closeWarning = () => MessageService.closeWarning();
    vm.closeError = () => MessageService.closeError();
    vm.closeInfo = () => MessageService.closeInfo();


    // get each object messages
    vm.getSuccessProcessed = () => MessageService.getSuccessProcessed();
    vm.getWarningProcessed = () => MessageService.getWarningProcessed();
    vm.getErrorProcessed = () => MessageService.getErrorProcessed();
    vm.getInfoProcessed = () => MessageService.getInfoProcessed();

    // get each object messages
    vm.getSuccessMessages = () => MessageService.getSuccessMessages();
    vm.getWarningMessages = () => MessageService.getWarningMessages();
    vm.getErrorMessages = () => MessageService.getErrorMessages();
    vm.getInfoMessages = () => MessageService.getInfoMessages();

    // Showing Methods
    vm.showSuccess = () => MessageService.showSuccess();  // show success
    vm.showWarning = () => MessageService.showWarning();  // show waring
    vm.showError = () => MessageService.showError();      // show errors
    vm.showInfo = () =>  MessageService.showInfo();       // show info


}

export default MessageController;
import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import LookupController from "components/blocworx/components/lookup/controllers/lookup.controller";

"use strict";

class LookupComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/lookup';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        main: '<',
        lc: '<',
        entry: '<',
        scanStation: '<',
        data: '<'
    }

    // This will set what will be the controller of this component
    public controller : any = LookupController;

    // This will set an alias
    public controllerAs: string = 'lc';
}

angular.module('BlocworxModule').component('lookup', <StandardComponent> new LookupComponent);
import * as angular from 'angular';
import * as $ from "jquery";

"use strict";

angular.module('BlocworxModule')
       .controller('SwitchController', SwitchController);

SwitchController.$inject = [];

// Controller Constructor
function SwitchController() {

    // Creating the view model reference
    let vm = this;
    vm.open = true;

    /**
     * Constructor class.
     */
    vm.$onInit = function () {
        try
        {
            $('navbar-switch').addClass('opened');
            $('blocworx-navbar').addClass('opened');

        } catch (e) {
            console.log(e);
        }
    }

    vm.toggle = function () {
        try {

            vm.open = !vm.open;
            if(vm.open === false){
                // $('#blocworxNavbar').hide();
                $('.sidebar').hide();
                $('navbar-switch').removeClass('opened');
                $('blocworx-navbar').removeClass('opened');
                $('.text-hover').text('Click to Open');
            } else {
                // $('#blocworxNavbar').show();
                $('.sidebar').show();
                $('navbar-switch').addClass('opened');
                $('blocworx-navbar').addClass('opened');
                $('.text-hover').text('Click to Hide');
            }

        } catch (e) {
            console.log(e);
        }
    }

}

export default SwitchController;
import * as angular from 'angular';
import MessageIF from "components/blocworx/components/message/interfaces/message.interface";
import * as $ from "jquery";

'use strict';

angular.module('BlocworxModule')
       .service('MessageService', MessageService);

MessageService.$inject = ['$http', 'Configuration'];

function MessageService($http, Configuration) {

    let service = this;
    service.showTime = 6000;

    let info : MessageIF = {
        messages: [],
        processed: [],
        show: false
    };

    let success : MessageIF = {
        messages: [],
        processed: [],
        show: false
    };

    let warning : MessageIF = {
        messages: [],
        processed: [],
        show: false
    };

    let error : MessageIF = {
        messages: [],
        processed: [],
        show: false
    };

    // get each object messages
    service.getSuccessMessages = () => success.messages;
    service.getWarningMessages = () => warning.messages;
    service.getErrorMessages = () => error.messages;
    service.getInfoMessages = () => info.messages;

    // get each object processed
    service.getSuccessProcessed = () => success.processed;
    service.getWarningProcessed = () => warning.processed;
    service.getErrorProcessed = () => error.processed;
    service.getInfoProcessed = () => info.processed;


    /**
     * This is the way to add a message.
     * @param message
     * @param object
     */
    service.addMessage = (message, object) => {

        if(object.messages.filter(item => item == message).length > 0){
            console.log('You tried to get multiple messages by multiple clicks');
            return;
        }

        // adding to the message array
        object.messages = [...object.messages, message];

        // saying that is necessary to show the message
        object.show = true;

    }

    // those are specific ways to add a message
    service.addSuccessMessage = (message) =>  service.addMessage(message, success);
    service.addWarningMessage = (message) =>  service.addMessage(message, warning);
    service.addErrorMessage = (message) =>  service.addMessage(message, error);
    service.addInfoMessage = (message) =>  service.addMessage(message, info);

    service.processData = (object) => {
        object.processed = [...object.processed, ...object.messages]
        object.messages = []
        object.show = false
    }

    /**
     * This method is to show things in this component, you must check all
     * functions that call this method,
     *
     * @param object
     */
    service.show = (object : MessageIF, cssClass) => {

        if(object.messages.length == 0 || object.show == false){
            return false;
        }

        $(`.${cssClass}`).show().fadeOut(service.showTime, function(){
            service.processData(object);
        });

        return true;
    }

    // Showing Methods
    service.showSuccess = () => service.show(success, 'success');   // show success
    service.showWarning = () => service.show(warning, 'warning');   // show waring
    service.showError = () => service.show(error, 'error');         // show errors
    service.showInfo = () =>  service.show(info, 'info');           // show info

    /**
     * This is responsible to close the message block.
     * @param object
     */
    service.close = (object) => {
        service.processData(object);
    }

    service.closeSuccess = () => service.close(success);  // close success
    service.closeWarning = () => service.close(warning);  // close waring
    service.closeError = () => service.close(error);      // close errors
    service.closeInfo = () => service.close(info);        // close info

    service.new = () => {
        info = {
            messages: [],
            processed: [],
            show: false
        };

        success = {
            messages: [],
            processed: [],
            show: false
        };

        warning  = {
            messages: [],
            processed: [],
            show: false
        };

        error  = {
            messages: [],
            processed: [],
            show: false
        };
    }
}

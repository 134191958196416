import * as angular from 'angular';
import AuthenticationController from "./controllers/auth.controller";
import StandardComponent from "components/standards/components/StandardComponent";

"use strict";

class BlocworxAuth extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/authentication';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        main: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = AuthenticationController;

    // This will set an alias
    public controllerAs: string = 'authentication';
}

angular.module('BlocworxModule').component('blocworxAuthentication', <StandardComponent> new BlocworxAuth);
import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .service('AdminFactory', AdminFactory);

AdminFactory.$inject = ['Configuration', '$http'];

/**
 * Service constructor.
 * @constructor
 */
function AdminFactory(Configuration, $http) {
    let factory = this;

}
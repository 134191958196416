"use strict";

import * as angular from "angular";

angular.module('BlocworxModule').config(homeRoutes);

homeRoutes.$inject = ['ConfigurationProvider', '$stateProvider'];

function homeRoutes(ConfigurationProvider, $stateProvider)
{
    $stateProvider.state('profile-page', {
        parent: 'app',
        url: '/profile-page',
        views: {
            'parent': {
                templateUrl: 'components/blocworx/components/user/template/profile-page.html?nd=' + Date.now(),
                controller: 'MainController as main'
            }, params: {}
        },
        params: {
            sidebarHighlighter: 'profile-page'
        }
    });

}
import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('AuthenticationController', AuthenticationController);

AuthenticationController.$inject = ['$scope', 'AlertFactory','ScopeAndLocal'];

// Controller Constructor
function AuthenticationController($scope, AlertFactory, ScopeAndLocal) {

    // Creating the view model reference
    let vm = this;
    vm.alertFactory = null;

    /**
     * Constructor class.
     */
    vm.$onInit = function () {
        try {

            vm.alertFactory = AlertFactory;
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'main');

        } catch (e) {
            console.log(e);
        }
    }

}

export default AuthenticationController;
import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('UserController', UserController);

UserController.$inject = ['$http', 'User', '$rootScope', '$state','$scope', 'ScopeAndLocal'];

// Controller Constructor
function UserController($http, User, $rootScope, $state, $scope, ScopeAndLocal) {

    // Creating the view model reference
    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = async () => {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'main');

            vm.users;
            vm.error;
            vm.dataEntered = false;

            if ($state.current.name == 'add-user') {
                vm.userData = {};
                vm.userData.userRoles = [];
                vm.userData.roleCheck = [];
            }



        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will load the userData after we have all the mainController
     * data from the frontend done.
     *
     * @constructor
     */
    vm.loadUserData = async () => {


        if ($state.current.name == 'manage-users') {
            await vm.getUsers();
        }

        if ($state.current.name == 'edit-users' || $state.current.name == 'profile-page') {
            vm.userId = vm.main.auth.userData.id !== undefined ? vm.main.auth.userData.id : $state.params.id;
            await vm.getUser(vm.userId);
        }
    }


    // get all users
    vm.getUsers = async function () {
        try
        {
            let users = await User.getUsers()
            vm.users = users.data;

        } catch (e) {
            console.log(e);
        }

    }

    // get the existing user
    vm.getUser = async function (id) {
        try
        {
            let user = await User.getUser(id);
            vm.userData = user.data.user_details;
            vm.userData.userRoles = [];
            vm.userData.roleCheck = [];

            for (let i in user.data.user_roles) {

                // dont add admin to this
                if (user.data.user_roles[i].role_id != 1) {
                    vm.userData.userRoles.push(user.data.user_roles[i].role_id);
                    vm.userData.roleCheck[user.data.user_roles[i].role_id] = 'true';
                }
            }

        } catch (e) {
            console.log(e);
        }

    }

    // check the existing roles for the checkboxes, we need to wait till vm.userData is defined before digging further otherwise errors are thrown
    vm.checkRole = function (role_id) {
        try
        {
            if (typeof (vm.userData) != 'undefined') {
                for (let i in vm.userData.userRoles) {
                    if (vm.userData.userRoles[i] == role_id) {
                        return true;
                    }
                }
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.addRemoveRole = function (name, role_id) {
        try
        {
            // if is checked this means we simply add the value, else we must remove it from the array
            if (name != 'false') {
                vm.userData.userRoles.push(role_id);
            } else {
                for (let i = 0; i < 50; i++) {
                    if (typeof (vm.userData.userRoles[i]) != 'undefined' && vm.userData.userRoles[i] == role_id) {
                        vm.userData.userRoles.splice(i, 1);
                    }
                }
            }

        } catch (e) {
            console.log(e);
        }

    }

    // update existing user
    vm.editUser = async function (newUserData) {
        try
        {
            await User.editUser(newUserData)
            vm.dataEntered = true;

        } catch (e) {
            console.log(e);
        }

    }

    // change password
    vm.editPassword = async function (id, password) {
        try
        {
            await User.editPassword(id, password);
            vm.dataEntered = true;

        } catch (e) {
            console.log(e);
        }

    }

    // add new user
    vm.addUser = async function (newUserData) {
        try
        {
            await User.addUser(newUserData);
            vm.dataEntered = true;

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateUser = async function (updateData) {
        try
        {
            let errMsg;
            if (updateData.password) {
                let match = updateData.passwordConfirm === updateData.password;
                if (!match || !updateData.passwordConfirm) {
                    vm.errMsg = 'The passwords you entered do not match, please try again.';
                    return;

                }

                let validPassword = User.validatePassword(updateData.password);
                if(!validPassword) {
                    vm.errMsg = "Password must contain at least 1 uppercase, 1 lowercase, 1 digit and 2 special characters from [£$&+,:;=?@#|'<>.^*()%!-] and be at least 8 characters long.";
                    return;
                }

                if (validPassword && match) {
                    await User.updateUser(updateData);
                    await vm.getUser(vm.userData.id);
                    vm.updateUserData = {};
                    $scope.$apply();
                    alert('Your details have been updated');
                } else {
                    if(errMsg){
                        alert(errMsg);
                    }
                }

            } else {
                await User.updateUser(updateData);
                await vm.getUser(vm.userData.id);
                vm.updateUserData = {};
                $scope.$apply();
                alert('Your details have been updated');

            }

        } catch (e) {
            console.log(e);
        }

    }

}

export default UserController;
import * as angular from 'angular';

"use strict";

angular.module('BreadcrumbModule')
       .controller('BreadcrumbController', BreadcrumbController);

BreadcrumbController.$inject = [ '$scope', 'ScopeAndLocal', 'Auth'];

// Controller Constructor
function BreadcrumbController($scope, ScopeAndLocal, Auth) {
    // Creating the view model reference
    let vm = this;
    vm.isEditMode = false;
    vm.scanStation = '';
    vm.data = '';
    vm.auth = Auth;

    vm.scanStationEditCases = () => {
        return vm.isEditMode;
    }

    vm.checkIfIsEditMode = () => {

        let editPlaces = [
            'scan-station-edit'
        ];

        if (vm.data !== undefined && vm.data.stateName !== undefined) {
            vm.isEditMode = editPlaces.includes(vm.data.stateName);
        }

    }

    vm.$onInit = function () {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'management');

            vm.checkIfIsEditMode();

        } catch ( e ) {
            console.log(e);
        }
    }

    vm.isEditBloc = function () {
        try {
            return vm.data.stateName === 'edit-bloc';
        } catch ( e ) {
            console.log(e);
        }
    }

    vm.scanStationEdit = function () {
        try {
            return vm.data.stateName === 'scan-station-edit';
        } catch ( e ) {
            console.log(e);
        }
    }

    vm.isScanStation = function () {
        try {
            return vm.data.stateName !== 'scan-station'

        } catch ( e ) {
            console.log(e);
        }

    }

    vm.isBloc = function () {
        try {
            return vm.data.stateName === 'bloc';
        } catch ( e ) {
            console.log(e);
        }

    }

    /**
     * This will check that for some users we will be returning true to hidden the
     * breadcrumb, so we will be having some conditionals for the cases
     * that we are hiding, later on this function must call from a query
     * and check on the system configuration if is enable or disable
     * the breadcrumbs.
     */
    vm.showBreadcrumbs = () => {

        let show = true;
        let userData = vm.auth.getUserData();

        // this is the conditional to hide the breadcrumbs for eazy-accounts
        if(userData.subDomain != null && userData.subDomain == "eazy-accounts"){
            show = false;
        }

        // the back button is a different way of navigating to breadcrumbs, for this reason we use
        // one or the other
        if(vm.scanStation != null && vm.scanStation.scanStationObj != null && vm.scanStation.scanStationObj.stationDetails.showBackButton == 1) {
            show = false;
        }

        return show;
    }

    /**
     * This is a function that will get an exception for the domain
     * https://iqutech-pl.blocworx.com
     */
    vm.showPageBarWithNumbers = () => {

        let show = false;
        let userData = vm.auth.getUserData();

        // this is the conditional to hide the breadcrumbs for eazy-accounts
        if(userData.subDomain != null && userData.subDomain == "iqutech-pl"){
            show = true;
        }

        return show;
    }

    /**
     * For all other cases that arent iqutech-pl
     * we shouldn't show the breadcrumb with some data in it
     * this is only an exception for blocworx poland.
     */
    vm.showNormalPageBar = () => {
        return !vm.showPageBarWithNumbers();
    }

}

export default BreadcrumbController;
import * as angular from 'angular';

"use strict";

angular.module('BackButtonModule')
       .controller('BackButtonController', BackButtonController);

BackButtonController.$inject = [ '$scope', 'ScopeAndLocal', 'Auth'];

// Controller Constructor
function BackButtonController($scope, ScopeAndLocal, Auth) {
    // Creating the view model reference
    let vm = this;
    vm.isEditMode = false;
    vm.scanStation = '';
    vm.data = '';
    vm.auth = Auth;

    vm.scanStationEditCases = () => {
        return vm.isEditMode;
    }

    vm.checkIfIsEditMode = () => {

        let editPlaces = [
            'scan-station-edit'
        ];

        if (vm.data !== undefined && vm.data.stateName !== undefined) {
            vm.isEditMode = editPlaces.includes(vm.data.stateName);
        }

    }

    vm.$onInit = function () {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'scanStation');
            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'management');

            vm.checkIfIsEditMode();

        } catch ( e ) {
            console.log(e);
        }
    }

    vm.isEditBloc = function () {
        try {
            return vm.data.stateName === 'edit-bloc';
        } catch ( e ) {
            console.log(e);
        }
    }

    vm.scanStationEdit = function () {
        try {
            return vm.data.stateName === 'scan-station-edit';
        } catch ( e ) {
            console.log(e);
        }
    }

    vm.isScanStation = function () {
        try {
            return vm.data.stateName !== 'scan-station'

        } catch ( e ) {
            console.log(e);
        }

    }

    vm.isBloc = function () {
        try {
            return vm.data.stateName === 'bloc';
        } catch ( e ) {
            console.log(e);
        }

    }

    /*
        This function checks if we are showing the back button for the scan station
     */

    vm.showBackButton = function() {
        return vm.scanStation.scanStationObj.stationDetails.showBackButton == 1;
    }


}

export default BackButtonController;
import * as angular from 'angular';
import '../services/form.factory.ts'

"use strict";

angular.module('BlocworxModule')
    .controller('FormBlocworxController', FormBlocworxController);

FormBlocworxController.$inject = ['$scope','ScopeAndLocal'];

// Controller Constructor
function FormBlocworxController($scope, ScopeAndLocal) {

    // Creating the view model reference
    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try {

            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'management');


        } catch (e) {
            console.log(e);
        }
    }

}

export default FormBlocworxController;
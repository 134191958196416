import * as angular from 'angular';

'use strict';

angular.module('BlocworxModule')
       .factory('pageState', PageStateService);

PageStateService.$inject = ['$rootScope', '$window'];

function PageStateService($rootScope, $window) {

    let PageStateService = this;

    /**
     * Constructor method.
     */
     PageStateService.$onInit = () => {
        try
        {

        } catch (error) {
            console.log(error);
        }
    }

    const beforeUnloadListener = (event) => {
        return event.returnValue = 'Changes you made may not be saved.';
    }

    PageStateService.startEventListenersForPageChange = () => {
        $window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });

        $rootScope.$on('$stateChangeStart', function (event) {

            if (!confirm('Changes you made may not be saved.')) {
                event.preventDefault();
            }
        });
    }

    return PageStateService;
}

export default PageStateService;
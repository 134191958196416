import * as angular from 'angular';
import BreadcrumbController from "./controllers/breadcrumb.controller";
import StandardComponent from "components/standards/components/StandardComponent";

"use strict";

class BreadcrumbComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/breadcrumb';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        scanStation: '<',
        data: '<',
        main: '=',
        management : '='
    }

    // This will set what will be the controller of this component
    public controller : any = BreadcrumbController;

    // This will set an alias
    public controllerAs: string = 'bc';
}

angular.module('BreadcrumbModule').component('breadcrumb', <StandardComponent> new BreadcrumbComponent);
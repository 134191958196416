import * as angular from 'angular';

import UserController from "./controllers/user.controller";
import StandardComponent from "components/standards/components/StandardComponent";

"use strict";

class UserComponent extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/user';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        main: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = UserController;

    // This will set an alias
    public controllerAs: string = 'uc';
}

angular.module('BlocworxModule').component('blocworxUser', <StandardComponent> new UserComponent);
import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('TableBlocworxController', TableBlocworxController);

TableBlocworxController.$inject = ['$scope','ScopeAndLocal'];

// Controller Constructor
function TableBlocworxController($scope, ScopeAndLocal) {

    // Creating the view model reference
    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = async function () {
        try {


            ScopeAndLocal.startVariable(vm, $scope, 'data');
            ScopeAndLocal.startVariable(vm, $scope, 'main');
            ScopeAndLocal.startVariable(vm, $scope, 'management');


        } catch (e) {
            console.log(e);
        }
    }

}

export default TableBlocworxController;
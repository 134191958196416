import * as angular from 'angular';

import CopyrightController from "./controllers/copyright.controller";
import StandardComponent from "components/standards/components/StandardComponent";

"use strict";

class BlocworxCopyright extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/copyright';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        bootstrap: '<',
        nav: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = CopyrightController;

    // This will set an alias
    public controllerAs: string = 'copyright';
}

angular.module('BlocworxModule').component('blocworxCopyright', <StandardComponent> new BlocworxCopyright);
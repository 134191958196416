import * as angular from 'angular';
import jQuery from "jquery";
import ClickController from "components/blocworx/controllers/click.controller";


"use strict";

angular.module('ModuleModule')
    .directive('clickLinkDirective', ClickLinkDirective);

ClickLinkDirective.$inject = [];

function ClickLinkDirective() {
    let directive = this;

    return ({
        controller: ClickController,
        restrict: "A",
        scope: {
            data: '<',
            main: '<',
        },
        link: function (scope, element, attributes) {

            let elementClass = "." + attributes.class;
            let aElement = jQuery(element).find('a');

            // If someone clicks to the td, it will be clicking to the
            // a element inside of this directive
            element.bind('click', function (event) {
                if(aElement.length > 0){
                    jQuery(aElement)[0].click()
                }
            });
        }
    });
}
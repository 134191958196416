import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('pageLoadedController', PageLoadedController);

PageLoadedController.$inject = ['$scope', 'ScopeAndLocal'];

// Controller Constructor
function PageLoadedController($scope, ScopeAndLocal) {

    // Creating the view model reference
    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = function () {
        try {

        } catch (e) {
            console.log(e);
        }
    }
}

export default PageLoadedController;
import * as angular from 'angular';
import $ from 'jquery';

'use strict';

angular.module('BlocworxModule')
    .factory('AlertFactory', AlertFactory);

AlertFactory.$inject = ['$http', 'Configuration'];

/**
 * Object that will create a Icon Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @returns {AlertFactory}
 * @constructor
 */
function AlertFactory($http, Configuration) {

    let alertFactory = this;
    let alertTimer = 4000;
    let apiUrl = Configuration.getApiUrl();

    /**
     * Constructor method.
     */
    alertFactory.$onInit = () => {
        try
        {
            alertFactory.status = false;
            alertFactory.deleteAllDataConfirmationPhrase  = 'DELETEALLDATA'
            alertFactory.alertTimer  = 4000;
            alertFactory.alertID  = 'dialogContainer';

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This will trigger the PHP side of it to delete the data.
     */
    alertFactory.deleteAllData = async (scanStation) => {
        try
        {
            if (alertFactory.deletealldata === alertFactory.deleteAllDataConfirmationPhrase) {

                let apiBase = apiUrl + 'delete-data-scan-station';
                let config = {
                    id: alertFactory.id,
                    password: alertFactory.password,
                    scanStationID: alertFactory.scanStationID,

                };

                let success = await $http.post(apiBase, config);

                $('#dialogSuccessMessage').html(success.data.success);
                $('#dialogSuccess').removeClass('hide').removeClass('fade');
                setTimeout(function () {
                    $('#dialogSuccess').addClass('hide').addClass('fade');
                }, alertFactory.alertTimer);

                // after everything is 100% deleted, this will load the data again.
                scanStation.getData(alertFactory.scanStationID);

                // after finish it up should change the status to false, as was finished successfully
                alertFactory.closeAlert();

            } else {
                alert('You must confirm the phrase ' + alertFactory.deleteAllDataConfirmationPhrase);
                alertFactory.status = true;
            }

        } catch (exception) {
            console.log(exception);
            $('#dialogErrorMessage').html(exception.data.error);
            $('#dialogError').removeClass('hide').removeClass('fade');
            setTimeout(function () {
                $('#dialogError').addClass('hide').addClass('fade');
            }, alertFactory.alertTimer);
            alertFactory.status = true;
        }

    }

    /**
     * This will do the actions for a close alert, after successfull
     * or when someone click to close the alert.
     */
    alertFactory.closeAlert = () => {
        alertFactory.status = false;
        alertFactory.password = undefined;
        alertFactory.deletealldata = undefined;
    }

    // this will make sure that will be the constructor class like a controller one
    alertFactory.$onInit();

    return alertFactory;
}

import * as angular from 'angular';
import { debug } from 'console';

'use strict';

angular.module('AdvancedReportsModule')
    .service('AdvancedReportsService', AdvancedReportsService)
    .config(AdvancedReportsConfiguration);

/**
 * Object that will set aHrefSanitizationWhitelist for requests
 * in this factory.
 *
 * @param $compileProvider
 * @constructor
 */
function AdvancedReportsConfiguration($compileProvider: any) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file|blob):/);
}

AdvancedReportsService.$inject = ['$http', 'Configuration'];

function AdvancedReportsService($http, Configuration): void {
    let service = this;

    let apiUrl = Configuration.getApiUrl();

    service.getDataReport = async function (stationID: string, fieldsToReport: string, requestData: string, responseType: string, infoPage: string, dataFilteringData: string) {

        let apiBase = apiUrl + 'get-data-report';
        let config = {
            responseType: responseType,
            params: {
                stationID,
                requestedReportData: requestData,
                fieldsToReport,
                responseType,
                infoPage,
                dataFilteringData
            }
        };
        return $http.get(apiBase, config)
    }

    service.getSummaryCountReport = async function (stationID: string, requestedReportData: string, fieldsToReport: string) {
        let apiBase = apiUrl + 'get-summary-count-report';
        let config = {
            responseType: 'blob',
            params: {
                stationID,
                requestedReportData,
                fieldsToReport
            }
        };
        return $http.get(apiBase, config)
    }

    service.addAutomatedReport = function (newAutomatedReport: any) {
        const apiBase = apiUrl + 'add-automated-report';
        const data = {
            newAutomatedReport: newAutomatedReport
        };
        return $http.post(apiBase, data);
    }

    return service;
}

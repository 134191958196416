import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .factory('FormFactory', FormFactory);

FormFactory.$inject = [];

/**
 *
 * @constructor
 */
function FormFactory() {

    let factory = this;

    return factory;
}

export default FormFactory;
import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import TableBlocworxController from "components/blocworx/components/table/controllers/tableBlocworx.controller";

"use strict";

class TableBlocworx extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/table';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        data: '<',
        main: '<',
        management: '<'
    }

    // This will set what will be the controller of this component
    public controller : any = TableBlocworxController;

    // This will set an alias
    public controllerAs: string = 'tb';


}

angular.module('BlocworxModule').component('tableBlocworx', <StandardComponent> new TableBlocworx);


import * as angular from 'angular';

'use strict';

angular.module('BlocworxModule')
       .factory('status', StatusService);

StatusService.$inject = ['$rootScope'];

/**
 * Object that will create a Status Factory, all methods of factory
 * will be available from outside.
 *
 * @param $http
 * @returns {StatusService}
 * @constructor
 */
function StatusService($rootScope) {

    let StatusService = this;

    // Define your factory content
    let status = {
        'success': false
    };

    /**
     * Constructor method.
     */
    StatusService.$onInit = () => {
        try
        {
            StatusService.startWatchers();

        } catch (exception) {
            console.log(exception);
        }
    }

    /**
     * This start all watchers
     */
    StatusService.startWatchers = () => {
        $rootScope.$watch(function () {
            return status.success;
        }, function (newValue, oldValue, scope) {

            // for the status success change to true
            if(status.success === true){
                $rootScope.$broadcast('runAfterSuccess', { newValue, oldValue });
            }
        }, true);
    }

    StatusService.runAfterSuccess = (callback, objects) => {
        $rootScope.$on('runAfterSuccess', function (event, { newValue, oldValue }) {
            callback(newValue, oldValue, objects);
        });
    }

    StatusService.successfulScan = () => {
        status.success = true
    }

    StatusService.isSuccess = () => {
        return status.success === true ? true : false;
    }

    // this will make sure that will be the constructor class like a controller one
    StatusService.$onInit();

    return StatusService;
}

export default StatusService;
import * as angular from 'angular';

"use strict";

angular.module('BlocworxModule')
       .controller('LogoController', LogoController);

LogoController.$inject = ['$scope', 'ScopeAndLocal', 'ThemeService', 'AdminService'];

// Controller Constructor
function LogoController($scope, ScopeAndLocal, ThemeService, AdminService) {

    // Creating the view model reference
    let vm = this;

    /**
     * Constructor class.
     */
    vm.$onInit = async () => {
        try
        {
            ScopeAndLocal.startVariable(vm, $scope, 'bootstrap');
            vm.subDomain = vm.bootstrap.subDomain
            vm.theme = ThemeService;

            // This will be responsible to load the main logo, if for some reason is empty, must get the default LogoSrc.
            vm.theme.logoSrc = await vm.theme.getMainLogo();

        } catch (e) {
            console.log(e);
        } finally {
            $scope.$apply();
        }
    }


}

export default LogoController;
import * as angular from 'angular';
import StandardComponent from "components/standards/components/StandardComponent";
import NavbarController from "./controllers/navbar.controller";
import SwitchController from "./controllers/switch.controller";
import ToggleController from "./controllers/toggle.controller";

"use strict";

class BlocworxNavbar extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/navbar';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        bootstrap: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = NavbarController;

    // This will set an alias
    public controllerAs: string = 'nav';
}

angular.module('BlocworxModule').component('blocworxNavbar', <StandardComponent> new BlocworxNavbar);

class BlocworxNavbarMobile extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/navbar';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        bootstrap: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = NavbarController;

    // This will set an alias
    public controllerAs: string = 'nav';
}

angular.module('BlocworxModule').component('blocworxNavbarMobile', <StandardComponent> new BlocworxNavbarMobile);

class BlocworxSwitch extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/navbar';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
    }

    // This will set what will be the controller of this component
    public controller : any = SwitchController;

    // This will set an alias
    public controllerAs: string = 'bswitch';
}
angular.module('BlocworxModule').component('navbarSwitch', <StandardComponent> new BlocworxSwitch);


class BlocworxToggle extends StandardComponent {

    // This will enable the debug mode of this class
    public debug = false;
    public moduleFolder = 'components/blocworx/components/navbar';

    /**
     * PLease make sure that each function contain correct
     * documentation.
     */
    public bindings = {
        bootstrap: '<',
        nav: '<',
    }

    // This will set what will be the controller of this component
    public controller : any = ToggleController;

    // This will set an alias
    public controllerAs: string = 'tc';
}
angular.module('BlocworxModule').component('navbarToggle', <StandardComponent> new BlocworxToggle);
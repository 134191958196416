import * as angular from 'angular';
import SkinSettingsController from "components/blocworx/controllers/skinSettings.controller";
import * as jQuery from "jquery";

'use strict';

angular.module('BlocworxModule')
       .directive('skinSettings', skinSettings);

skinSettings.jQueryinject = [];

/**
 *  Object that will create a Icon Factory, all methods of factor
 *
 * @constructor
 */
function skinSettings() {

    let skinSettings = this;
    return ({
        restrict: "A",
        scope: {
            skinSettings: '='
        },
        controller: function ($scope, $element, $attrs) {
            $scope.$watch('skinSettings', function (newValue, oldValue) {
                if(newValue){

                    // remove all possible classes to add
                    let bodyElement = $element.parent().parent().parent();

                    jQuery(bodyElement).removeClass('dark').removeClass('bright')
                        .addClass(newValue)

                    if(jQuery('iframe')){
                        jQuery('iframe').each( (index, element) => {

                            // changes in the main body class
                            jQuery(element).contents().find('.blocworx-iframe')
                                .removeClass('dark')
                                .removeClass('bright')
                                .addClass(newValue)

                            // changes int the main skin-settings element
                            let iframeClass = "skin-setting-" + newValue;
                            jQuery(element).contents().find('#iframe')
                                .removeClass('skin-setting-dark')
                                .removeClass('skin-setting-bright')
                                .addClass(iframeClass)

                        });
                    }
                }
            }, true);
        }
    });
}
